<script setup lang="ts">
import type { DropdownItem } from '#ui/types';
defineProps<{
  context?: string;
}>();

const userStore = useUserStore();
const { profile } = storeToRefs(userStore);

const profileItems: DropdownItem[][] = [
  [
    {
      label: 'My Profile',
      icon: 'i-ph-user-circle',
      to: '/account/profile',
    },
    {
      label: 'Settings',
      icon: 'i-ph-gear',
      to: '/account/profile',
    },
  ],
  [
    {
      label: 'Help & Support',
      icon: 'i-ph-question',
      to: 'https://docs.respell.ai',
    },
  ],
  [
    {
      label: 'Log out',
      icon: 'i-ph-sign-out',
      click: userStore.logout,
    },
  ],
];
</script>

<template>
  <div class="flex flex-row justify-between w-full px-m pt-s h-16">
    <div v-if="context === 'account'" class="flex">
      <app-back-button to="/" />
      <span class="title">Settings</span>
    </div>

    <NuxtLink v-else to="/" class="pl-2">
      <app-logo responsive />
    </NuxtLink>

    <UDropdown
      :items="profileItems"
      :ui="{ width: 'w-52', item: { padding: 'py-3' } }"
      :popper="{ placement: 'bottom-start' }"
      class="shrink-0"
    >
      <UAvatar :alt="profile?.fullName" size="md" />

      <template #item="{ item }">
        <UIcon :name="item.icon" class="flex-shrink-0 h-6 w-6 text-gray-500" />
        <span class="truncate body ml-2">{{ item.label }}</span>
      </template>
    </UDropdown>
  </div>
</template>
