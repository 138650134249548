<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types';
import type { Workspace } from '@respell/database';
import { z } from 'zod';
import { useWorkspaceStore } from '~/stores/workspaces';

const modal = useModal();
const toast = useToast();

const schema = z.object({
  name: z.string().min(1, { message: 'Workspace name cannot be empty.' }),
});

type Schema = z.output<typeof schema>;

const workspaceStore = useWorkspaceStore();
const { createWorkspace } = workspaceStore;

interface Props {
  workspace: Workspace;
}

const { workspace } = defineProps<Props>();

const state = reactive({
  name: '',
});

const onSubmit = async (event: FormSubmitEvent<Schema>) => {
  const {
    data: { name },
  } = event;

  const workspace: Workspace = await createWorkspace(name);

  modal.close();

  if (workspace) {
    toast.add({
      title: 'Successfully created workspace.',
      id: 'modal-success',
    });
  } else {
    toast.add({
      title: 'Unable to create workspace.',
      id: 'modal-failure',
      color: 'red',
    });
  }
};
</script>
<template>
  <UModal>
    <UForm
      :schema="schema"
      :state="state"
      class="w-full space-y-4"
      @submit="onSubmit"
    >
      <UCard
        :ui="{
          ring: '',
          divide: '',
        }"
      >
        <template #header>
          <div class="main-title">Create a new workspace</div>
        </template>

        <div class="space-y-4">
          <UFormGroup label="Name" name="name" required>
            <UInput
              v-model="state.name"
              placeholder="My workspace..."
              required
            />
          </UFormGroup>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-2">
            <UButton type="button" size="xl" color="white" @click="modal.close"
              >Cancel
            </UButton>
            <UButton type="submit" size="xl">Create Workspace</UButton>
          </div>
        </template>
      </UCard>
    </UForm>
  </UModal>
</template>
